import React, { FC } from 'react';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';
import { IconButton } from 'wix-ui-tpa';
import { st, classes } from './ShareButton.st.css';

export const ShareButton: FC = () => {
  return (
    <IconButton
      icon={<ShareIcon />}
      className={st(classes.root)}
      onClick={() => alert('Share clicked')}
    />
  );
};
