import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { PlaybackComponentProps } from '../../Player.types';
import Player from '@vimeo/player';
import s from './Vimeo.scss';

type Props = PlaybackComponentProps & {
  player: Player;
};

const PlayerAPI: FC<Props> = ({ player, playing, muted = false, onEnd }) => {
  useLayoutEffect(() => {
    player.setMuted(muted);
  }, [player, muted]);

  useLayoutEffect(() => {
    playing ? player.play() : player.pause();
  }, [player, playing]);

  useLayoutEffect(() => {
    onEnd && player.on('ended', onEnd);
  }, [player, onEnd]);
  return null;
};

export const Vimeo: FC<PlaybackComponentProps> = (props) => {
  const { src, muted } = props;
  const [player, setPlayer] = useState<Player>();

  const setRef = useCallback(
    (iframe: HTMLIFrameElement | null) => {
      if (iframe) {
        setPlayer(
          new Player(iframe, {
            muted,
          }),
        );
      }
    },
    [muted],
  );

  return (
    <>
      <iframe
        ref={setRef}
        title="Vimeo"
        className={s.vimeo}
        src={src}
        frameBorder="0"
        allowFullScreen
        allow="autoplay; encrypted-media"
      />
      {player ? <PlayerAPI {...props} player={player} /> : null}
    </>
  );
};
