import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { createMemoryHistory } from 'history';
import { withTranslation, withWixSdk } from '@wix/yoshi-flow-editor';
import {
  createFunctionProxy,
  createWorkerHandlerMiddleware,
  consumeEvents,
} from './lib';
import { HttpClient } from '@wix/http-client';
import { getAllSettings, isResponsiveEditor } from '../selectors/app-settings';
import { updateWindowSize } from '../redux/actions/window-size';
import { WidgetPropsProvider } from '../containers/widget-props';
import { setInitialAppSettings } from '../redux/actions/app-settings';
import { setInstance } from '../configure-client';
import { setHydratedData } from '../redux/hydrated-data/hydrated-data';
import { logBi } from './actions/bi';
import { experimentsStore } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { initPublicServices } from '../api/public';
import { initPublicServicesV3 } from '../api/v3/public';
import { initWixSDK } from '../utils/wix-sdk';
import { StyleParamsCSSVariables } from '../components/style-params-css-variables/StyleParamsCSSVariables';
import { initTranslation } from '../utils/translation';
import { updateControllerState } from '../redux/actions/controller-state';
import { ControllerStateContext } from '../containers/withHandlersAndState';
import { initPrivateServices } from '../api/private';

const {
  parseStyleParams,
} = require('@wix/wix-vod-shared/dist/src/widget/data/style-params/parse');

const withErrorBoundary = (Comp) => {
  return class BoundaryWrapComponent extends React.Component {
    state = { error: false };
    componentDidCatch(err, errorInfo) {
      this.props.captureException(err, { extra: { reactInfo: errorInfo } });
      this.setState({ error: true });
    }
    render() {
      if (this.state.error) {
        return null;
      }
      return <Comp {...this.props} />;
    }
  };
};

export function widgetWrapper({ createStore, Component }) {
  const withSettings = connect((state) => ({
    styleParams: getAllSettings(state),
  }));

  const StyledComponent = withSettings(({ styleParams, ...props }) => {
    const mergedProps = {
      ...props,
      host: {
        ...props.host,
        style: {
          ...props.host.style,
          styleParams: styleParams || props.style.styleParams,
        },
      },
    };

    return (
      <StyleParamsCSSVariables>
        <Component {...mergedProps} />
      </StyleParamsCSSVariables>
    );
  });

  class StoreWrapper extends React.Component {
    static propTypes = {
      hydratedSource: PropTypes.object,
      pubSubEvents: PropTypes.object,
      style: PropTypes.object,
      t: PropTypes.func.isRequired,
      configData: PropTypes.shape({
        baseUrl: PropTypes.string,
        instance: PropTypes.string,
      }),
    };

    getDimensions(props) {
      return props.dimensions || props.host.dimensions;
    }

    constructor(props) {
      super(props);

      const {
        configData: { instance, baseUrl },
        experiments,
        wixSDK: { Wix },
        isSSR,
        t,
        handlers,
        controllerState,
      } = props;

      const httpClient = new HttpClient({
        getAppToken: () => this.props.configData.instance,
      });

      initTranslation(t);
      initWixSDK(Wix);
      experimentsStore.set(experiments);

      initPrivateServices(httpClient);
      initPublicServices(httpClient, instance, baseUrl);
      initPublicServicesV3(httpClient, instance, baseUrl);

      const functionsProxy = (this.functionsProxy = createFunctionProxy());

      this.resolve = _.noop;

      if (isSSR) {
        this.promise = new Promise((_resolve) => {
          this.resolve = _resolve;
        });
      }

      const dispatch = (action) =>
        props.dispatchEv(functionsProxy.serializeAction(action));

      const dispatchOnPageReady = async (action) => {
        await this.promise;
        return dispatch(action);
      };

      const middlewares = [
        createWorkerHandlerMiddleware(isSSR ? dispatchOnPageReady : dispatch),
      ];

      const { configData, appState, host } = this.props;

      this.history = createMemoryHistory({ initialEntries: [configData.url] });

      this.store = createStore({
        middlewares,
        initialState: appState,
        history: this.history,
        handlers,
      });

      if (controllerState) {
        this.store.dispatch(updateControllerState(controllerState));
      }

      if (isSSR) {
        this.promise.then(this.logWidgetLoaded);
      } else {
        this.logWidgetLoaded();
      }

      this.updateInstance();

      this.store.dispatch(
        setInitialAppSettings(
          parseStyleParams(host.style, isResponsiveEditor(appState)),
        ),
      );

      this.updateWindowSize();
    }

    updateInstance() {
      setInstance(this.props.configData.instance);
    }

    componentDidUpdate(prevProps) {
      const { controllerState } = this.props;

      if (
        prevProps.renderingEnv === 'backend' &&
        this.props.renderingEnv === 'browser'
      ) {
        this.resolve();
      }

      if (!_.isEqual(prevProps.configData, this.props.configData)) {
        this.updateInstance();
        this.store.dispatch(
          setHydratedData({
            instance: this.props.configData.instance,
          }),
        );
      }
      if (
        !_.isEqual(
          this.getDimensions(prevProps),
          this.getDimensions(this.props),
        )
      ) {
        this.updateWindowSize();
      }

      if (prevProps.controllerState !== controllerState) {
        this.store.dispatch(updateControllerState(controllerState));
      }
    }

    logWidgetLoaded = () => {
      this.store.dispatch(
        logBi('widget.loaded', {
          channelID: '00000000-0000-0000-0000-000000000000',
        }),
      );
    };

    updateWindowSize() {
      const dimensions = this.getDimensions(this.props);

      let width = 0;
      let height = 0;

      if (dimensions) {
        width = dimensions.width || width;
        height = dimensions.height || height;

        if (typeof document !== 'undefined') {
          width = width || document.body.clientWidth; // isFullWidth
        }
      } else {
        if (typeof document !== 'undefined') {
          width = document.body.clientWidth;
          height = document.documentElement.clientHeight;
        }
      }

      this.store.dispatch(updateWindowSize({ width, height }));
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      consumeEvents(newProps, (event) => {
        this.functionsProxy.callFunction(event);
      });
    }

    render() {
      const { store } = this;
      const { controllerState, handlers } = this.props;

      return (
        <ReduxProvider store={store}>
          <WidgetPropsProvider value={this.props}>
            <ControllerStateContext.Provider
              value={{
                handlers,
                controllerState,
              }}
            >
              <StyledComponent {...this.props} />
            </ControllerStateContext.Provider>
          </WidgetPropsProvider>
        </ReduxProvider>
      );
    }
  }

  return withErrorBoundary(withTranslation()(withWixSdk(StoreWrapper)));
}
