import { createContext, useContext } from 'react';
import { GqlVideoFragment } from '../apollo/generated/graphql';

export type SelectedVideoContextType = {
  selectedVideo: GqlVideoFragment;
  selectVideo(video: GqlVideoFragment): void;
  selectedVideoChanged: boolean;
};

export const SelectedVideoContext =
  createContext<SelectedVideoContextType | null>(null);

export function useSelectedVideo() {
  const context = useContext(SelectedVideoContext);

  if (!context) {
    throw new Error('Use this hook within SelectedVideoContext');
  }

  return context;
}
