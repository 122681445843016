import React, { FC } from 'react';
import { Overlay } from '../Overlay/Overlay';
import { useChannelAccessButton, usePlayChannelText } from '../../../../hooks';
import { useChannel } from '../../../../hooks/useChannel';

export const ChannelOverlay: FC = () => {
  const playChannelText = usePlayChannelText();
  const channelAccessButton = useChannelAccessButton();
  const channel = useChannel();

  const videoUrl = channel.play.entity.url;

  return (
    <Overlay
      playButtonText={playChannelText}
      paidButton={channelAccessButton}
      title={channel.title}
      coverUrl={channel.cover.selected}
      videoUrl={videoUrl}
    />
  );
};
