import React, { FC, useEffect } from 'react';
import { useDesktopLayout } from '../hooks/useDesktopLayout';
import { Classic } from './components/Classic/Classic';
import { useBi } from '@wix/yoshi-flow-editor';
import { widgetLoadComplete } from '@wix/bi-logger-video/v2';

export const DesktopWidget: FC = () => {
  const { isClassic } = useDesktopLayout();
  const bi = useBi();

  useEffect(() => {
    bi.report(widgetLoadComplete({}));
  }, [bi]);

  // TODO: loadable: https://bo.wix.com/wix-docs/fe-guild/viewer-platform/articles/loadable-components-in-ooi-apps
  if (isClassic) {
    return <Classic />;
  }

  return null;
};
