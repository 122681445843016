import React, { FC } from 'react';
import CloseIcon from 'wix-ui-icons-common/on-stage/Close';
import { IconButton } from 'wix-ui-tpa';
import { st, classes } from './PlayerOverlayCloseButton.st.css';
import { usePlayerOverlayActions } from '../PlayerOverlayProvider/PlayerOverlayProvider';

export const PlayerOverlayCloseButton: FC<{
  className?: string;
  ariaLabel: string;
}> = ({ className, ariaLabel }) => {
  const { closeOverlay } = usePlayerOverlayActions();

  return (
    <IconButton
      className={st(classes.closeButton, className)}
      icon={<CloseIcon />}
      onClick={closeOverlay}
      aria-label={ariaLabel}
      data-hook="player-overlay-close-button"
    />
  );
};
