import { useStyles } from '@wix/tpa-settings/react';
import React, { FC, useCallback, useState } from 'react';
import { PlaybackContext } from '../../hooks/usePlayback';
import stylesParams from '../../../../stylesParams';

export const PlaybackProvider: FC = ({ children }) => {
  const styles = useStyles();
  const shouldAutoplay = styles.get(stylesParams.autoPlay);
  const [muted, setMuted] = useState(shouldAutoplay);
  const [playing, setPlaying] = useState(shouldAutoplay);
  const play = useCallback(() => {
    setMuted(false);
    setPlaying(true);
  }, []);
  const pause = useCallback(() => setPlaying(false), []);

  return (
    <PlaybackContext.Provider
      value={{
        playing,
        play,
        pause,
        muted,
        setMuted,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  );
};
