import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Animate.scss';

type Props = {
  className?: string;
  type?: 'fade-in' | 'fade-in-out';
  open?: boolean;
  activeClassName?: string;
  dataHook?: string;
};

export const Animate: FC<Props> = ({
  type = 'fade-in',
  children,
  open,
  className,
  activeClassName = '',
  dataHook,
}) => {
  if (!children) {
    return null;
  }

  const wrapperClassNames = classNames(
    className,
    { [activeClassName]: open },
    styles[type],
    { [styles.active]: open },
  );

  return (
    <div
      {...(dataHook && open && { 'data-hook': dataHook })}
      className={wrapperClassNames}
      aria-hidden={!open}
    >
      {children}
    </div>
  );
};
