import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PublicChannelService } from '@wix/wix-vod-gql-api/dist/src/public/channel/channel';
import { PublicVideoCardsService } from '@wix/wix-vod-gql-api/dist/src/public/video-cards/video-cards';

export let publicApiV3: {
  channel: PublicChannelService;
  cards: PublicVideoCardsService;
};

export function initPublicServicesV3(
  httpClient: IHttpClient,
  authorization: string,
  baseUrl: string,
) {
  publicApiV3 = {
    channel: new PublicChannelService(authorization, baseUrl),
    cards: new PublicVideoCardsService(authorization, baseUrl),
  };
}
