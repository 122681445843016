import React, { FC, useCallback, useState } from 'react';
import { GqlVideoFragment } from '../../apollo/generated/graphql';
import { useChannelVideos } from '../../hooks/useChannelVideos';
import {
  SelectedVideoContext,
  SelectedVideoContextType,
} from '../../hooks/useSelectedVideo';

export const SelectedVideoProvider: FC = ({ children }) => {
  const { videos } = useChannelVideos();
  const [selectedVideo, setSelectedVideo] = useState<GqlVideoFragment>(
    videos[0],
  );
  const [selectedVideoChanged, setSelectedVideoChanged] = useState(false);

  const selectVideo = useCallback(
    (video: GqlVideoFragment) => {
      if (video.id !== selectedVideo.id) {
        setSelectedVideo(video);
      }

      setSelectedVideoChanged(true);
    },
    [setSelectedVideoChanged, setSelectedVideo, selectedVideo],
  );

  const value: SelectedVideoContextType = {
    selectedVideo,
    selectVideo,
    selectedVideoChanged,
  };

  return (
    <SelectedVideoContext.Provider value={value}>
      {children}
    </SelectedVideoContext.Provider>
  );
};
