import React, { FC } from 'react';
import { Animate } from '../Animate/Animate';
import styles from './ActionsPanel.scss';
import { useCurrentPlayerOverlay } from '../PlayerOverlayProvider/PlayerOverlayProvider';

const useIsOverlayShown = () => {
  const currentPlayerOverlay = useCurrentPlayerOverlay();
  return currentPlayerOverlay !== null;
};

export const ActionsPanel: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isOverlayShown = useIsOverlayShown();

  return isOverlayShown ? null : (
    <section className={styles.mainContentWrapper}>
      <Animate className={styles.mainContent}>
        <div className={styles.actions}>{children}</div>
      </Animate>
    </section>
  );
};
