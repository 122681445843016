import React from 'react';
import { withEnvironment, withFedopsLogger } from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  openBuyVideoOverlay,
  openSubscribeOverlay,
} from '../../redux/actions/mobile-overlay-openners/payment-pages-actions';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
} from '../../utils/open-overlay';
import { getVideosGroupedByIds } from '../../selectors/videos';
import {
  getVideoIdFromUrl,
  getUrlParam,
} from '../../selectors/query-params.js';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { areElementsRounded } from '../../selectors/app-settings';
import { toggleShareVideoOverlay } from '../../redux/actions/share-overlay';
import { isPayed } from '../../selectors/payments';
import ShareMobile from '../../mobile/components/share-mobile/share-mobile';
import {
  URL_PARAM_STATE,
  URL_PARAM_PAYMENT_TYPE,
  URL_STATE_PURCHASE,
  URL_STATE_SHARE,
  URL_STATE_SUBSCRIBE,
} from '@wix/wix-vod-shared/dist/src/common/constants/url';

import {
  getCompId,
  getFullSiteUrl,
} from '../../redux/hydrated-data/hydrated-data';
import { isMobile } from '../../selectors/form-factor';
import { openVideoFromUrlIfExists } from '../../mobile/initial-data-mobile';

const DISABLING_SESSION_KEY = 'vod-disable-deeplink-popups';

const mapStateToProps = (state) => {
  const videos = getVideosGroupedByIds(state) || {};
  return {
    channelData: getChannelForWidget(state),
    videoData: videos[getVideoIdFromUrl(state)],
    urlState: getUrlParam(state, URL_PARAM_STATE),
    paymentType: getUrlParam(state, URL_PARAM_PAYMENT_TYPE),
    areElementsRounded: areElementsRounded(state),
    siteUrl: getFullSiteUrl(state),
    compId: getCompId(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = {
  openBuyVideoOverlay,
  openSubscribeOverlay,
  toggleShareVideoOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openVideoFromUrlIfExists,
};

const ConnectedDeeplinkPopups = withEnvironment(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class DeeplinkPopups extends React.Component {
      static propTypes = {
        channelData: PropTypes.object,
        videoData: PropTypes.object,
        isMobile: PropTypes.bool,
        areElementsRounded: PropTypes.bool,
        urlState: PropTypes.string,
        paymentType: PropTypes.string,
        siteUrl: PropTypes.string,
        compId: PropTypes.string,
        openBuyVideoOverlay: PropTypes.func,
        openSubscribeOverlay: PropTypes.func,
        toggleShareVideoOverlay: PropTypes.func,
      };

      constructor(props) {
        super(props);

        this.state = {
          isShareOverlayOpened: false,
        };

        if (props.environment.isSSR) {
          this.hasOpenedPopup = true;
        } else {
          this.hasOpenedPopup =
            sessionStorage.getItem(DISABLING_SESSION_KEY) === 'true';
        }
      }

      componentDidMount() {
        this.openDeeplinkPopups(this.props);
      }

      UNSAFE_componentWillUpdate(nextProps) {
        this.openDeeplinkPopups(nextProps);
      }

      openDeeplinkPopups(props) {
        const { hasOpenedPopup } = this;
        const {
          channelData,
          urlState,
          videoData,
          openVideoFromUrlIfExists,
          isMobile,
        } = props;

        if (hasOpenedPopup || !videoData || _.isEmpty(channelData)) {
          return;
        }

        if (isMobile && !urlState && videoData) {
          openVideoFromUrlIfExists();
        }

        switch (urlState) {
          case URL_STATE_SHARE:
            this.openSharePopup();
            break;
          case URL_STATE_PURCHASE:
            this.openPurchasePopup(channelData, videoData);
            break;
          case URL_STATE_SUBSCRIBE:
            this.openSubscribePopup(channelData);
            break;
          default:
            break;
        }

        this.hasOpenedPopup = true;
        sessionStorage.setItem(DISABLING_SESSION_KEY, 'true');
      }

      openSharePopup() {
        if (!this.props.isMobile) {
          this.openShareOverlay();
        }
      }

      openPurchasePopup(channel, video) {
        const { openBuyVideoOverlay, fedops } = this.props;

        if (isPayed(video)) {
          return;
        }

        return this.props.isMobile
          ? openBuyVideoOverlay(video.id)
          : this.props.openFullScreenPurchaseOverlay(
              fedops,
              channel.id,
              video.id,
            );
      }

      openSubscribePopup(channel) {
        const { openSubscribeOverlay, fedops } = this.props;

        if (isPayed(channel)) {
          return;
        }

        return this.props.isMobile
          ? openSubscribeOverlay(channel.id)
          : this.props.openFullScreenSubscribeOverlay(fedops, channel.id);
      }

      openShareOverlay() {
        this.props.toggleShareVideoOverlay();
      }

      render() {
        const { channelData, urlState, videoData, isMobile } = this.props;

        if (isMobile && urlState === URL_STATE_SHARE) {
          return (
            <ShareMobile
              channelData={channelData}
              videoItem={videoData}
              isOpen
            />
          );
        }

        return null;
      }
    },
  ),
);

export default withFedopsLogger(ConnectedDeeplinkPopups);
