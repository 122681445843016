import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { FocusLock } from '../../../common/components/FocusLock/FocusLock';
import { Animate } from '../Animate/Animate';
import {
  PlayerOverlayWindow,
  useCurrentPlayerOverlay,
  usePlayerOverlayActions,
} from '../PlayerOverlayProvider/PlayerOverlayProvider';
import styles from './PlayerOverlay.scss';
import { Query } from 'react-container-query/lib/interfaces';
import { ContainerQuery } from 'react-container-query';
import { PlayerOverlayCloseButton } from '../PlayerOverlayCloseButton/PlayerOverlayCloseButton';
import { PlayerOverlayVideoInfo } from '../PlayerOverlayVideoInfo/PlayerOverlayVideoInfo';
import { useSelectedVideo } from '../../../hooks';
import { usePlayableFullScreenManager } from '../../../common/components/PlayableFullScreenManager/PlayableFullScreenManager';

const PlayerOverlayContent: FC<{
  currentOverlay: PlayerOverlayWindow;
}> = ({ currentOverlay }) => {
  const { t } = useTranslation();
  switch (currentOverlay) {
    case 'videoInfo': {
      return (
        <PlayerOverlayWrapper
          ariaLabel={t('widget.accessibility.video-info')}
          closeButtonAriaLabel={t('widget.accessibility.close-video-info')}
        >
          <PlayerOverlayVideoInfo />
        </PlayerOverlayWrapper>
      );
    }
    case 'shareVideo': {
      return <div>not implemented yet</div>;
    }
    default: {
      return null;
    }
  }
};

const useCloseOnEsc = () => {
  const { closeOverlay } = usePlayerOverlayActions();
  useEffect(() => {
    const escHandler = (event: KeyboardEvent) => {
      // Esc for IE/edge, Escape for others
      if (event.key === 'Esc' || event.key === 'Escape') {
        closeOverlay();
      }
    };

    document.addEventListener('keyup', escHandler);

    return () => {
      document.removeEventListener('keyup', escHandler);
    };
  }, [closeOverlay]);
};

const containerQuery: Query = {
  [styles.minWidth250px]: {
    minWidth: 250,
    maxWidth: 499,
  },
  [styles.minWidth500px]: {
    minWidth: 500,
    maxWidth: 979,
  },
  [styles.minWidth980px]: {
    minWidth: 980,
  },
};

const useHasPoster = () => {
  const { selectedVideo } = useSelectedVideo();
  return Boolean(selectedVideo.poster.selected);
};

const PlayerOverlayWrapper: FC<{
  children: React.ReactNode;
  ariaLabel: string;
  closeButtonAriaLabel: string;
}> = ({ children, ariaLabel, closeButtonAriaLabel }) => {
  useCloseOnEsc();
  const hasPoster = useHasPoster();
  const { isFullScreen } = usePlayableFullScreenManager();
  const shouldShowHalf = !hasPoster;

  return (
    <ContainerQuery query={containerQuery}>
      {(queryClasses) => (
        <div
          className={classNames(styles.overlayContainer, queryClasses)}
          role="dialog"
          aria-label={ariaLabel}
          data-hook="player-overlay-wrapper"
        >
          <div
            className={classNames(styles.overlayContent, {
              [styles.half]: shouldShowHalf,
              [styles.fullScreen]: isFullScreen,
            })}
          >
            {children}
          </div>
          <PlayerOverlayCloseButton
            className={styles.closeButton}
            ariaLabel={closeButtonAriaLabel}
          />
        </div>
      )}
    </ContainerQuery>
  );
};

export const PlayerOverlay: FC = () => {
  const currentOverlay = useCurrentPlayerOverlay();

  return (
    <Animate type="fade-in-out" open={Boolean(currentOverlay)}>
      {currentOverlay ? (
        <FocusLock>
          <PlayerOverlayContent currentOverlay={currentOverlay} />
        </FocusLock>
      ) : null}
    </Animate>
  );
};
