import React, { FC, useState } from 'react';
import classNames from 'classnames';
import s from './Overlay.scss';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';
import { PlayButton } from '../../../../common/components/PlayButton/PlayButton';
import { Button } from '../../../../common/components/Button/Button';
import { LiveLabel } from '../../../../common/components/LiveLabel/LiveLabel';
import { Playable } from '../../../../common/components/Player/players/Playable/Playable';
import { usePlayback } from '../../../../hooks';
import { Picture } from '../../../../common/components/Picture/Picture';

type Props = {
  playButtonText?: string;
  paidButton?: {
    text: string;
    handler(): void;
  };
  title?: string;
  coverUrl?: string;
  videoUrl?: string;
  showLiveLabel?: boolean;
};

const useOverlaySettings = () => {
  const styles = useStyles();
  const showTitle = styles.get(stylesParams.showTitleForMainItem);

  const opacity = styles.get(stylesParams.mainItemOverlayOpacity) / 100;
  return { showTitle, opacity };
};

const OverlayContent: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { opacity } = useOverlaySettings();
  const { play } = usePlayback();
  return (
    <div
      className={s.content}
      style={{
        backgroundColor: `rgba(0,0,0,${opacity})`,
      }}
      onClick={play}
    >
      {children}
    </div>
  );
};

export const Overlay: FC<Props> = ({
  coverUrl,
  showLiveLabel,
  playButtonText,
  paidButton,
  title,
  videoUrl,
}) => {
  const { showTitle } = useOverlaySettings();
  const [playing, setPlaying] = useState(false);

  return (
    <div className={s.root}>
      {videoUrl && (
        <Playable
          src={videoUrl}
          playing
          muted
          loop
          controlsHidden
          onPlay={() => setPlaying(true)}
        />
      )}
      {coverUrl && (
        <Picture
          className={classNames(s.picture, {
            [s.hidden]: coverUrl && playing,
          })}
          src={coverUrl}
          breakpoints={[
            {
              min: 0,
              width: 768,
              height: 432,
            },
          ]}
        />
      )}
      <OverlayContent>
        <div className={s.contentHeader}>
          {showLiveLabel ? <LiveLabel /> : null}
        </div>
        {showTitle ? (
          <h3 className={s.title} data-hook="classic-overlay-title">
            {title}
          </h3>
        ) : null}
        <div className={s.buttons}>
          {playButtonText && <PlayButton>{playButtonText}</PlayButton>}
          {paidButton && (
            <Button
              data-hook="classic-overlay-paid-button"
              onClick={paidButton.handler}
            >
              {paidButton.text}
            </Button>
          )}
        </div>
      </OverlayContent>
    </div>
  );
};
