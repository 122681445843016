import React, { FC } from 'react';
import { Overlay } from '../Overlay/Overlay';
import {
  usePlayback,
  useSelectedVideo,
  useVideoAccessButton,
  useVideoSource,
} from '../../../../hooks';
import { useVideoPlayButton } from '../../../../hooks/useVideoPlayButton';

export const VideoOverlay: FC = () => {
  const { selectedVideo } = useSelectedVideo();
  const { playing } = usePlayback();
  const { canPlay, playText } = useVideoPlayButton(selectedVideo);
  const videoAccessButton = useVideoAccessButton(selectedVideo);
  const { isStreamingLive } = useVideoSource(selectedVideo);

  return playing ? null : (
    <Overlay
      playButtonText={canPlay ? playText : undefined}
      paidButton={videoAccessButton}
      title={selectedVideo.title}
      coverUrl={selectedVideo.cover.selected}
      showLiveLabel={isStreamingLive}
    />
  );
};
