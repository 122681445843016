import { useUser } from './useUser';
// import { useErrorMonitor } from '@wix/yoshi-flow-editor';

export const useUserEmail = (): string => {
  const user = useUser();
  // const monitor = useErrorMonitor();
  if (!user.loggedIn) {
    // monitor.captureMessage(
    //   'The hook useUserEmail was called on the not logged in user and rendered empty',
    // );
    return '';
  }
  return user.email;
};
