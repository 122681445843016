import React, { FC, MouseEvent, useCallback } from 'react';
import PlayButtonIcon from 'wix-ui-icons-common/on-stage/PlayButton';
import { ThumbnailButton } from '../ThumbnailButton/ThumbnailButton';
import { GqlVideoFragment } from '../../../apollo/generated/graphql';
import { usePlayback, useSelectedVideo } from '../../../hooks';

type Props = {
  iconSize?: number;
  text?: string;
  video: GqlVideoFragment;
};

export const ThumbnailPlayButton: FC<Props> = ({ iconSize, video, text }) => {
  const { selectVideo } = useSelectedVideo();
  const { play } = usePlayback();
  const handlePlayClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      selectVideo(video);
      play();

      // prevent calling handleOverlayClick
      event.stopPropagation();
    },
    [play, selectVideo, video],
  );

  return (
    <ThumbnailButton
      Icon={PlayButtonIcon}
      onClick={handlePlayClick}
      iconSize={iconSize}
      text={text}
    />
  );
};
