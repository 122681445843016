import _ from 'lodash';
import { parseCatalogChannel } from './catalog';
import { parseYoutubeChannel } from '@wix/wix-vod-api/dist/src/public/youtube/parsers/channel';
import { mergeResponse } from './utils';

export const parseChannel = (channel) => {
  const youtubeData = channel.youtube_data;
  const parsedChannel = parseCatalogChannel(_.omit(channel, 'youtube_data'));

  if (youtubeData) {
    const parsedYoutubeItem = parseYoutubeChannel(
      parsedChannel.urlType,
      youtubeData,
    );
    return mergeResponse(parsedChannel, parsedYoutubeItem);
  }
  return parsedChannel;
};
