import { ReadOnlyExperiments } from '@wix/wix-experiments';
import { DesktopLayout } from '../types/enums';

export const shouldUseNewWidget = (
  channelLayout: number,
  isMobile: boolean,
  isChannel: boolean,
  experiments: ReadOnlyExperiments,
) => {
  if (!isMobile && isChannel) {
    switch (channelLayout) {
      case DesktopLayout.Classic:
        return experiments.enabled('specs.vod.ApolloDesktopClassicChannel');
    }
  }

  return false;
};
