import { createContext, useContext } from 'react';

export type PlaybackContextType = {
  muted: boolean;
  playing: boolean;
  play(): void;
  pause(): void;
  setMuted(muted: boolean): void;
};

export const PlaybackContext = createContext<PlaybackContextType | null>(null);

export function usePlayback() {
  const context = useContext(PlaybackContext);

  if (!context) {
    throw new Error('Use this hook within PlaybackContext');
  }

  return context;
}
