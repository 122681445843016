import React, { FC, useEffect, useMemo } from 'react';
import { DesktopWidget } from './desktop/DesktopWidget';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from './apollo/client';
import { useBi, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { StyleParamsCSSVariables } from '../old/components/style-params-css-variables/StyleParamsCSSVariables';
import { MobileWidget } from './mobile/MobileWidget';
import { FetchAdapter } from './worker/fetch/fetchAdapter';
import { useChannelId } from './hooks';
import { Channel } from './common/components/Channel/Channel';
import { SingleVideo } from './common/components/SingleVideo/SingleVideo';
import { WorkerContext } from './hooks/useWorker';
import { widgetOpened } from '@wix/bi-logger-video/v2';
import { Direction } from './common/components/Direction/Direction';
import { ControllerProps } from './controller';

export type NewWidgetProps = WidgetProps<ControllerProps>;

export const NewWidget: FC<NewWidgetProps> = (props) => {
  const {
    instance,
    initialCacheData,
    gqlHost,
    doPost,
    responses,
    removeResponse,
    modals,
    purchases,
    pricingPlans,
    user,
  } = props;
  const channelId = useChannelId();
  const bi = useBi();
  const { finishedPurchasesCount } = purchases;
  const fetchAdapter = useMemo(
    () => new FetchAdapter(instance, doPost, removeResponse),
    [instance, doPost, removeResponse],
  );

  const client = useMemo(
    () =>
      createApolloClient(
        gqlHost,
        instance,
        fetchAdapter,
        finishedPurchasesCount > 0 ? undefined : initialCacheData, // reload on purchase
      ),
    [instance, initialCacheData, gqlHost, fetchAdapter, finishedPurchasesCount],
  );

  const { isMobile } = useEnvironment();

  const widget = useMemo(
    () => (isMobile ? <MobileWidget /> : <DesktopWidget />),
    [isMobile],
  );

  useEffect(() => {
    fetchAdapter.setResponses(responses);
  }, [fetchAdapter, responses]);

  useEffect(() => {
    bi.report(widgetOpened({}));
  }, [bi]);

  return (
    <Direction>
      <ApolloProvider client={client}>
        <WorkerContext.Provider
          value={{ modals, purchases, pricingPlans, user }}
        >
          <StyleParamsCSSVariables>
            {channelId ? (
              <Channel>{widget}</Channel>
            ) : (
              <SingleVideo>{widget}</SingleVideo>
            )}
          </StyleParamsCSSVariables>
        </WorkerContext.Provider>
      </ApolloProvider>
    </Direction>
  );
};
