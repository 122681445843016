import { GqlVideoFragment } from '../apollo/generated/graphql';
import { useChannelPricing, useVideoPricing, useVideoSource } from '.';
import { AccessAction } from '../types/enums';
import { useChannelAccessAction } from './useChannelAccessAction';

export function useVideoAccessAction(video: GqlVideoFragment): AccessAction {
  const { rentEnabled, saleEnabled, isFreeVideo } = useVideoPricing(video);
  const { purchaseOrRentDisabled, isFreeChannel } = useChannelPricing();
  const { isExternalVideo } = useVideoSource(video);
  const channelAccessAction = useChannelAccessAction();

  if (video) {
    if (isExternalVideo) {
      return AccessAction.None;
    }

    if (!isFreeChannel && (isFreeVideo || purchaseOrRentDisabled)) {
      return channelAccessAction;
    }

    if (rentEnabled && saleEnabled) {
      return AccessAction.BuyOrRent;
    }

    if (rentEnabled) {
      return AccessAction.Rent;
    }

    if (saleEnabled) {
      return AccessAction.Buy;
    }
  }

  return AccessAction.None;
}
